import { Section } from "@/lib/OrgTypes";

export const requiredFields = [
  "common_org_name",
  "legal_org_name",
  "industry_sector",
  "site_control_type",
  "esg_strategy",
  "specific_goals",
];

export default [
  {
    key: "about_us",
    label: "About us",
    icon: "star",
    fields: [
      {
        key: "common_org_name",
        question: "What is the trade, or common name, of your organization?",
        type: "textfield",
        label: "Organization name",
        display: [],
      },
      {
        key: "legal_org_name",
        question:
          "What is your organization's full legal name used for contracting purposes?",
        type: "textfield",
        label: "Legal organization name",
        display: [],
      },
      {
        key: "year_established",
        question: "What year was your company established?",
        type: "textfield",
        label: "Year established",
        mask: "####",
        display: [],
      },
      {
        key: "website_url",
        question:
          "Please provide your company’s website URL. Paste the URL directly from your browser. Test that your URL works in your Provider Summary Card above.",
        type: "textfield",
        subtype: "url",
        label: "Website URL",
        display: [],
      },
      {
        key: "divider",
        type: "divider",
        display: ["block"],
      },
      {
        key: "industry_sector",
        question: "What sector or kind of organization do you represent?",
        type: "list",
        label: "Industry sector",
        display: ["list", "hide_select_all"],
        options: [
          "Business Services",
          "Energy & Utilities",
          "Accounting",
          "Saas Customer",
          "Airlines/Aviation",
          "Vendor",
          "Alternative Dispute Resolution",
          "Channel",
          "Alternative Medicine",
          "Investor",
          "Animation",
          "Agriculture",
          "Apparel & Fashion",
          "Architecture & Planning",
          "Arts & Crafts",
          "Communications",
          "Automotive",
          "Aviation & Aerospace",
          "Consulting",
          "Banking",
          "Education",
          "Biotechnology",
          "Energy",
          "Broadcast Media",
          "Engineering",
          "Building Materials",
          "Environmental",
          "Business Supplies & Equipment",
          "Finance",
          "Capital Markets",
          "Government",
          "Chemicals",
          "Civic & Social Organization",
          "Manufacturing",
          "Civil Engineering",
          "Media",
          "Commercial Real Estate",
          "Not For Profit",
          "Computer & Network Security",
          "Recreation",
          "Computer Games",
          "Computer Hardware",
          "Shipping",
          "Computer Networking",
          "Technology",
          "Computer Software",
          "Internet",
          "Transportation",
          "Construction",
          "Consumer Electronics",
          "Healthcare",
          "Consumer Goods",
          "Consumer Services",
          "Cosmetics",
          "Dairy",
          "Defense & Space",
          "Design",
          "Education Management",
          "E-Learning",
          "Electrical/Electronic Manufacturing",
          "Entertainment",
          "Environmental Services",
          "Events Services",
          "Executive Office",
          "Facilities Services",
          "Farming",
          "Financial Services",
          "Fine Art",
          "Fishery",
          "Food & Beverages",
          "Food Production",
          "Fund-Raising",
          "Furniture",
          "Gambling & Casinos",
          "Glass, Ceramics & Concrete",
          "Government Administration",
          "Government Relations",
          "Graphic Design",
          "Health, Wellness and Fitness",
          "Higher Education",
          "Hospital & Health Care",
          "Hospitality",
          "Human Resources",
          "Import and Export",
          "Individual & Family Services",
          "Industrial Automation",
          "Information Services",
          "Information Technologyand Services",
          "Insurance",
          "International Affairs",
          "International Trade and Development",
          "Investment Banking",
          "Investment Management",
          "Judiciary",
          "Law Enforcement",
          "Law Practice",
          "Legal Services",
          "Legislative Office",
          "Leisure, Travel & Tourism",
          "Libraries",
          "Logistics and Supply Chain",
          "Luxury Goods & Jewelry",
          "Machinery",
          "Management Consulting",
          "Maritime",
          "Market Research",
          "Marketing and Advertising",
          "Mechanical or Industrial Engineering",
          "Media Production",
          "Medical Devices",
          "Medical Practice",
          "Mental Health Care",
          "Military",
          "Mining & Metals",
          "Motion Pictures and Film",
          "Museums and Instituitons",
          "Music",
          "Nanotechnology",
          "Newspapers",
          "Non-Profit Organization Management",
          "Oil & Energy",
          "Online Media",
          "Outsourcing/Offshoring",
          "Package/Freight Delivery",
          "Packaging and Containers",
          "Paper & Forest Products",
          "Performing Arts",
          "Pharmaceuticals",
          "Philanthropy",
          "Photography",
          "Plastics",
          "Political Organization",
          "Primary/Secondary Edication",
          "Printing",
          "Professional Training & Coaching",
          "Program Development",
          "Public Policy",
          "Public Relations and Communications",
          "Public Safety",
          "Publishing",
          "Railroad Manufacture",
          "Ranching",
          "Real Estate",
          "Recreational Facilities and Services",
          "Religious Instituitons",
          "Renewables & Environment",
          "Research",
          "Retail",
          "Security and Investigations",
          "Semiconductors",
          "Shipbuilding",
          "Sporting Goods",
          "Sports",
          "Stagging and Recruiting",
          "Supermarkets",
          "Telecommunications",
          "Textiles",
          "Think Tanks",
          "Tobacco",
          "Translation and Localization",
          "Transportation/Trucking/Railroad",
          "Utilities",
          "Venture Capital & Private Equity",
          "Veterinary",
          "Warehousing",
          "Wholesale",
          "Wine and Spirits",
          "Wireless",
          "Writing and Editing",
          "Other",
        ],
      },
      {
        key: "site_control_type",
        question: "Do you generally own or lease?",
        type: "list",
        label: "Site control type",
        display: [],
        options: [
          {
            label: "Own",
            value: "own",
          },
          {
            label: "Lease",
            value: "lease",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        single_select: true,
      },
      {
        key: "specific_goals",
        question:
          "What are the primary goals you are trying to achieve with clean energy?",
        type: "list",
        label: "Goals",
        display: ["list"],
        options: [
          {
            label: "Reduced energy bill",
            value: "reduced_energy_bill",
          },
          {
            label: "Reduced carbon footprint",
            value: "reduced_carbon_footprint",
          },
          {
            label: "Predictability in energy expense",
            value: "predictability_energy_expenses",
          },
          {
            label: "Energy resiliency",
            value: "resiliency",
          },
          {
            label: "Net operating income (NOI) improvments",
            value: "noi_improvements",
          },
          {
            label: "Public relations value",
            value: "public_relations_value",
          },
          {
            label: "Local job creation",
            value: "local_job_creation",
          },
          {
            label: "Environmental justice",
            value: "environmental_justice",
          },
        ],
      },
      {
        key: "desired_project_type",
        question:
          "Currently, are you looking to find a pool of qualified providers and pricing (via an RFQ)? Or are you ready to contract with an awarded provider for a specific project (via an RFP)?",
        type: "list",
        label: "Desired project type",
        display: [],
        options: [
          {
            label: "Request for Quotes (RFQ)",
            value: "rfq",
          },
          {
            label: "Request for Proposals (RFP)",
            value: "rfp",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
      },
      {
        key: "esg_strategy",
        question:
          "What is your company-wide ESG goal, sustainability goal, or renewables strategy?",
        type: "textarea",
        placeholder:
          "eg. Scope 1+2 reduction, inclusion in a specific ESG index fund, etc.",
        display: ["block"],
        label: "ESG Strategy",
      },
      {
        key: "data_room_divider",
        type: "divider",
        display: ["block", "management_mode_only"],
      },
      {
        key: "data_room_link",
        question:
          'Please provide the data room link for this buyer to use. Paste the URL directly from your browser. Test that your URL works in the "Data Room" button above.',
        type: "textfield",
        subtype: "url",
        label: "Data Room Link",
        display: ["management_mode_only"],
      },
    ],
  },
] as Array<Section>;
