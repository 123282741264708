export default {
  install(Vue = {}) {
    Vue.prototype.$validate = {
      email(n, message) {
        return (
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(n || "") || message
        );
      },
      required(n, message) {
        return (n || "").length > 0 || message;
      },
      blank(n, message) {
        return (n || "").trim().length > 0 || message;
      },
    };
  },
};
