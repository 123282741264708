import {
  breakpointsTailwind,
  useBreakpoints as useBreakpointsVueUse,
} from "@vueuse/core";

export function useBreakpoints() {
  // NOTE: breakpointsTailwind uses the default Tailwind breakpoints, so we will need to change this
  // if we ever diverge from the defaults
  const breakpoints = useBreakpointsVueUse(breakpointsTailwind);

  return {
    ...breakpoints,
    mobile: breakpoints.smaller("md"),
    nonMobile: breakpoints.greaterOrEqual("md"),
  };
}
