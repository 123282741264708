import {
  PortfolioLocation,
  PortfolioLocationOffers,
  PortfolioLocationOverview,
  Note,
} from "../types/PortfolioInterface";
import { BaseAPIClient } from "./API";

export class PortfolioDownloadError extends Error {
  constructor(status: number, ...params: string[]) {
    super(...params);
    this.status = status;
    switch (status) {
      case 425:
        this.type = "jobs-in-progress";
        break;
      default:
        this.type = "generic-portfolio-download-error";
        break;
    }
  }
  status: number;
  type: string;
}

export class PortfoliosClient extends BaseAPIClient {
  async getOrganizationPortfolios() {
    const url = `/portfolios`;
    const res = await this.safeRequest({ url });
    return res.data.portfolios;
  }

  async create(
    portfolio_name: string,
    hubspot_id: string,
    organization_id: string,
    file: File
  ) {
    const url = `/portfolios`;
    const formData = new FormData();
    formData.append("portfolio_name", portfolio_name);
    formData.append("hubspot_id", hubspot_id);
    formData.append("organization_id", organization_id);
    formData.append("portfolio_file", file);

    const res = await this.safeRequest({
      url,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    return res;
  }

  async get(portfolioId: string) {
    const url = `/portfolios/${portfolioId}`;
    const res = await this.safeRequest({ url });
    if (res.status === 404) {
      throw Error("Portfolio not found");
    }
    return res.data;
  }

  async download(portfolioId: string) {
    const url = `/portfolios/${encodeURIComponent(portfolioId)}/export.csv`;
    const res = await this.safeRequest({ url });
    if (res.status === 200) {
      return res.data;
    } else {
      throw new PortfolioDownloadError(res.status, res.data);
    }
  }

  async getLocations(portfolioId: string) {
    const url = `/portfolios/${portfolioId}/locations`;
    const res = await this.safeRequest({ url });
    const { locations } = res.data;
    return locations.map(
      (l: { [key: string]: any }) => new PortfolioLocation(l)
    );
  }

  async getLocationNotes(portfolioId: string, locationId: string) {
    const url = `/portfolios/${portfolioId}/locations/${locationId}/notes`;
    const res = await this.safeRequest({ url });
    const { notes } = res.data;
    return notes;
  }

  async createLocationNote(
    portfolioId: string,
    locationId: string,
    data: Note
  ) {
    const url = `/portfolios/${portfolioId}/locations/${locationId}/notes`;
    const res = await this.safeRequest({
      url,
      method: "POST",
      data,
    });
    return res.data;
  }

  async getPortfolioNotes(portfolioId: string) {
    const url = `/portfolios/${portfolioId}/notes`;
    const res = await this.safeRequest({ url });
    const { notes } = res.data;
    return notes;
  }

  async getGeoJSONs(portfolioId: string) {
    const url = `/portfolios/${portfolioId}/locations/geojson`;
    const res = await this.safeRequest({ url });
    return res.data.features.map(
      (f: { [key: string]: any }) => new PortfolioLocationOverview(f)
    );
  }

  async getLocationGeoJSONs(portfolioId: string, locationId: string) {
    const url = `/portfolios/${portfolioId}/locations/${locationId}/geojson`;
    const res = await this.safeRequest({ url });
    return res.data.features.map(
      (f: { [key: string]: any }) => new PortfolioLocationOverview(f)
    );
  }

  async getLocationMetrics(portfolioId: string, pagination: any) {
    const url = `/portfolios/${portfolioId}/export_metrics`;
    const params = pagination
      ? {
          offset: (pagination.page - 1) * pagination.pageSize,
          sort_by: pagination.sortBy,
          sort_order: pagination.sortOrder,
          limit: pagination.pageSize,
          search_string: pagination.searchString,
        }
      : {};
    const res = await this.safeRequest({ url, params });
    return res.data.map(
      (m: { [key: string]: any }) => new PortfolioLocationOffers(m)
    );
  }

  async getPortfolioSummary(portfolioId: string) {
    const url = `/portfolios/${portfolioId}/export_summary`;
    const res = await this.safeRequest({ url });
    return res.data;
  }

  async starLocation(portfolioId: string, locationId: string) {
    const url = `/portfolios/${portfolioId}/locations/${locationId}/star`;
    const res = await this.safeRequest({
      url,
      method: "POST",
    });
    return res.data;
  }

  async unstarLocation(portfolioId: string, locationId: string) {
    const url = `/portfolios/${portfolioId}/locations/${locationId}/unstar`;
    const res = await this.safeRequest({
      url,
      method: "POST",
    });
    return res.data;
  }

  async showLocation(portfolioId: string, locationId: string) {
    const url = `/portfolios/${portfolioId}/locations/${locationId}/show`;
    const res = await this.safeRequest({
      url,
      method: "POST",
    });
    return res.data;
  }

  async hideLocation(portfolioId: string, locationId: string) {
    const url = `/portfolios/${portfolioId}/locations/${locationId}/hide`;
    const res = await this.safeRequest({
      url,
      method: "POST",
    });
    return res.data;
  }
}
