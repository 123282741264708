import { computed, ref, watch, Ref } from "vue";
import { toRef, MaybeRefOrGetter, useAsyncState } from "@vueuse/core";
import { intersection } from "lodash-es";
import router from "../router";

import { Field, Section, Provider } from "@/lib/OrgTypes";
import { ProvidersClient } from "@/lib/ProviderAPI";
import { Proposal } from "@/lib/useProposal";
import ProviderFields from "./ProviderFields";

export function useProviderProfile(
  providerId: MaybeRefOrGetter<string | undefined>
) {
  const reactiveProviderId = toRef(providerId);

  const provider = ref<Provider | undefined>();
  const initialLoadComplete = ref(false);
  const providerLoading = ref(true);
  const providerClient = new ProvidersClient();

  const reloadProvider = async () => {
    providerLoading.value = true;
    try {
      if (!reactiveProviderId.value) {
        provider.value = undefined;
        return;
      }
      const maybeProvider = await providerClient.getProvider(
        reactiveProviderId.value
      );
      if (maybeProvider) {
        provider.value = maybeProvider;
      }
      return provider;
    } catch (e) {
      router.push({ name: "not-found" }).catch(() => {});
    } finally {
      providerLoading.value = false;
      initialLoadComplete.value = true;
    }
  };

  watch(reactiveProviderId, reloadProvider, { immediate: true });

  return {
    provider,
    initialLoadComplete,
    reloadProvider,
    providerLoading,
  };
}

export function isNonEmptyValue(value: any) {
  // This method controls whether or not we want to show that this field is
  // complete/has a valid answer to the user
  if (value === null) return false;

  // Since empty arrays are truthy, we need to check that it is not empty
  if (Array.isArray(value) && value.length === 0) return false;

  // We want to allow a user to input 0
  if (typeof value === "number" || typeof value === "boolean") return true;

  // This allows for attachments to be marked as complete
  return !!value;
}

export function providerProfileCompleteness(
  inProvider: MaybeRefOrGetter<Provider | undefined>
) {
  const provider = toRef(inProvider);

  function getSectionRequiredComplete(provider: Provider, section: Section) {
    const completedRequiredFields = section.fields.filter(
      (field: Field) =>
        isNonEmptyValue(provider[field.key]) &&
        provider.required_fields_for_proposal_submission!.includes(field.key)
    );
    return completedRequiredFields.length;
  }

  function getSectionTotalRequired(provider: Provider, section: Section) {
    const required_fields = intersection(
      section.fields.map((field: Field) => field.key),
      provider.required_fields_for_proposal_submission
    );
    return required_fields ? required_fields.length : 0;
  }

  // NOTE: This is expressed this way so that Vue's dependency tracking actually works; previously
  // getSectionRequiredComplete and getSectionTotalRequired were exposed to callers, but since they
  // are not computed values, Vue could not properly track data dependencies leading to a reactivity
  // bugs where data remained stale.
  const completenessBySection = computed(() =>
    ProviderFields.map((section) => ({
      key: section.key,
      requiredComplete: provider.value
        ? getSectionRequiredComplete(provider.value, section)
        : null,
      totalRequired: provider.value
        ? getSectionTotalRequired(provider.value, section)
        : null,
    }))
  );

  function getPercentComplete() {
    if (!provider.value) {
      return 0;
    }
    const allRequiredFields =
      provider.value!.required_fields_for_proposal_submission;
    if (!allRequiredFields) {
      return 0;
    }
    const completedRequiredFields = allRequiredFields.filter((key: string) =>
      isNonEmptyValue(provider.value![key])
    );
    return (completedRequiredFields.length / allRequiredFields.length) * 100;
  }

  const percentComplete = computed(() => {
    if (provider.value) {
      const rawPercentComplete = getPercentComplete();
      if (rawPercentComplete) {
        return Math.round(rawPercentComplete);
      }
    }
    return 0;
  });

  const isComplete = computed(() => percentComplete.value === 100);

  const progressChipStatus = computed(() => {
    if (!percentComplete.value) {
      return "not-started";
    }
    if (percentComplete.value === 100) {
      return "completed";
    } else if (percentComplete.value < 100) {
      return "in-progress";
    } else {
      return "not-started";
    }
  });

  return {
    percentComplete,
    isComplete,
    completenessBySection,
    getPercentComplete,
    getSectionRequiredComplete,
    getSectionTotalRequired,
    progressChipStatus,
  };
}

export function useProviderProfilesForProposals(proposals: Ref<Proposal[]>) {
  const providerClient = new ProvidersClient();

  const {
    state: proposalProviders,
    isReady: ready,
    isLoading: providersLoading,
    error: error,
    execute: reloadProviders,
  } = useAsyncState(
    async () => {
      const proposalProviders: { [key: string]: Provider } = {};
      if (proposals.value) {
        for (const proposal of proposals.value) {
          const provider = await providerClient.getProvider(
            proposal.providerId
          );
          if (provider) {
            proposalProviders[proposal.id] = provider;
          }
        }
      }
      return proposalProviders;
    },
    {},
    { resetOnExecute: false }
  );

  watch(proposals, async () => await reloadProviders());

  return {
    proposalProviders,
    providersLoading,
    ready,
    error,
  };
}
