<script setup lang="ts">
import { ref } from "vue";

import { useAccessToken } from "../lib/useAccessToken";
import { useTerms } from "../lib/useTerms";
import { AccountClient } from "../lib/API";

const emit = defineEmits<{
  (event: "accept"): void;
  (event: "reject"): void;
}>();

const { clearAccessToken } = useAccessToken();
const { acceptTerms, termsAreUpToDate, termsLoading } = useTerms();

const termsAgreed = ref(false);

const accountClient = new AccountClient();

async function onAcceptTerms() {
  await acceptTerms();
  emit("accept");
}
async function onRejectTerms() {
  await accountClient.logout();
  clearAccessToken();
  emit("reject");
}
</script>

<template>
  <v-dialog
    persistent
    max-width="720px"
    :value="!termsLoading && !termsAreUpToDate"
  >
    <v-card>
      <v-card-title>
        <v-icon left>description</v-icon>
        <h2 class="subheading">Our terms have changed</h2>
      </v-card-title>
      <v-card-text class="subheading pt-0">
        <p>
          We have updated our Terms of Service, which will be effective on
          October 7, 2021. By checking the box and clicking "I Agree" below, you
          acknowledge that you have read, understood, and agree to the updated
          <a href="https://stationa.com/terms" target="_blank"
            >Station A Terms of Service</a
          >.
        </p>
        <v-checkbox color="primary" v-model="termsAgreed">
          <template v-slot:label>
            I have read, understood, and agree to the updated terms
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click="onRejectTerms">I Do Not Agree</v-btn>
        <v-spacer />
        <v-btn
          flat
          @click="onAcceptTerms"
          :disabled="!termsAgreed"
          :class="termsAgreed && 'primary--text'"
        >
          I Agree
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
