import { ref } from "vue";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Mutex } from "async-mutex";

import { debug, warn, errormsg } from "./logging";
import { readAccessTokenCookie } from "./useAccessToken";
import { createEventHook, createGlobalState } from "@vueuse/core";

/**
 * A TokenRefreshFn is a magical async function that generates an access token
 */
type TokenRefreshFn = () => Promise<string | null>;

const useGlobalTokenRefreshFn = createGlobalState(() =>
  ref<TokenRefreshFn>(nullTokenRefresh)
);

const useGlobalTokenRefreshHook = createGlobalState(() =>
  createEventHook<string>()
);

/**
 * Default token refresh function that doesn't actually do anything
 */
async function nullTokenRefresh() {
  warn("Token refresher is disabled!");
  return readAccessTokenCookie();
}

export class TokenRefresher {
  protected client: AxiosInstance;
  protected lock: Mutex;

  /**
   * Class that implements the {@link TokenRefreshFn} protocol by interacting with the Station A API's
   * auth:refresh operation
   *
   * @param config - Configuration options for the internal {@link AxiosInstance}
   */
  constructor(config: AxiosRequestConfig) {
    this.client = axios.create(config);
    this.lock = new Mutex();
  }

  /**
   *  Call the token refresh API endpoint to pick up the new access token value.
   */
  public async refreshToken() {
    const releaseFunc = await this.lock.acquire();

    try {
      await this.client.request({
        url: "/auth/refresh",
        method: "POST",
        withCredentials: true,
      });
      return readAccessTokenCookie();
    } catch (error) {
      errormsg("Could not fetch new access token: " + error);
      return null;
    } finally {
      releaseFunc();
    }
  }
}

/**
 * Composable for interacting with the token refresh function
 */
export function useTokenRefresher() {
  const tokenRefreshFn = useGlobalTokenRefreshFn();
  const tokenRefreshedHook = useGlobalTokenRefreshHook();

  function setTokenRefreshFn(newTokenRefreshFn: TokenRefreshFn) {
    tokenRefreshFn.value = newTokenRefreshFn;
  }

  /**
   * Attempts to refresh the current access token
   *
   * @returns A true/false depending on whether or not the access token was successfully refreshed
   */
  async function checkedRefreshToken(): Promise<boolean> {
    try {
      const oldToken = readAccessTokenCookie();
      const newToken = await tokenRefreshFn.value();
      if (oldToken !== newToken && newToken) {
        debug("New access token: " + newToken);
        tokenRefreshedHook.trigger(newToken);
        return true;
      }
    } catch (error) {
      errormsg("Could not update access token: " + error);
    }

    return false;
  }

  return {
    refreshToken: checkedRefreshToken,
    setTokenRefreshFn,
    /**
     * Event hook that gets called whenever the access token is refreshed
     */
    onTokenRefresh: tokenRefreshedHook.on,
  };
}
