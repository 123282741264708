<template>
  <div class="tw-bg-white tw-rounded-xl dark:tw-bg-zinc-800">
    <slot />
  </div>
</template>

<script>
export default {
  name: "t-card",
};
</script>
