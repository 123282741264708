import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/stylus/main.styl";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify, {
  iconfont: "mdi",
  theme: {
    primary: "#33cadd",
    secondary: "#fff59d",
    error: "#ff627d",
    warning: "#fff59d",
    upcoming: "#69f0ae",
    blue: "#33cadd",
    green: "#69f0ae",
    "blood-orange": "#ff5a18",
    yellow: "#fae168",
    orange: "#f4b64f",
    purple: "#7e57c2",
    grey: "#afafaf",
    black: "#111111",
    red: "#ff627d",
    lavender: "#b8a1e0",
    gradeAPlus: "#69f0ae",
    gradeA: "#69f0ae",
    gradeB: "#a9e6b0",
    gradeC: "#a9e6b0",
    gradeD: "#fae168",
    gradeE: "#87c9fa",
  },
  options: {
    customProperties: true,
  },
});
