import env from "../env";

export function debug(txt: string) {
  if (env.debug) {
    console.debug(txt);
  }
}

export function warn(txt: string) {
  console.warn(txt);
}

export function errormsg(txt: string) {
  console.error(txt);
}
