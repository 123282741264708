<template>
  <v-dialog scrollable max-width="640px" :value="true" persistent>
    <loading-state-card
      v-if="loading"
      icon="manage_accounts"
      card-title="We're setting up your account"
    />
    <v-card v-else>
      <v-card-title>
        <v-icon left>manage_accounts</v-icon>
        <h2 class="headline">Select your organization type</h2>
      </v-card-title>
      <v-card-text class="subheading pt-0">
        Before we start, tell us a bit more about yourself so we can customize
        your Station A experience!
      </v-card-text>
      <v-card-text>
        <v-card light color="green">
          <v-card-title>
            <v-icon left>location_city</v-icon>
            <h2 class="headline">Do you buy clean energy?</h2></v-card-title
          >
          <v-card-text class="subheading pt-0">
            Are you looking to buy clean energy? Station A saves you time,
            money, and the headache of finding the best provider for your energy
            and sustainability needs.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn flat @click="selectBuyer">I am a buyer</v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-4" light color="blue">
          <v-card-title>
            <v-icon left>construction</v-icon>
            <h2 class="headline">Do you sell clean energy?</h2></v-card-title
          >
          <v-card-text class="subheading pt-0">
            Do you develop, finance, or build clean energy? Station A can help
            you explore market trends, find new projects, and connect with
            buyers.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn flat @click="selectProvider">I am a provider</v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useTokenRefresher } from "../lib/useTokenRefresher";
import { useAuth } from "../lib/useAuth";
import LoadingStateCard from "@/components/LoadingStateCard.vue";

export default {
  name: "org-type-select-dialog",
  components: {
    LoadingStateCard,
  },
  setup() {
    const { refreshToken } = useTokenRefresher();
    const { organizationId } = useAuth();
    return { refreshToken, organizationId };
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    autoSelect: {
      type: String,
    },
  },
  async created() {
    if (this.autoSelect) {
      if (this.autoSelect === "buyer") {
        await this.selectBuyer();
      } else if (this.autoSelect === "provider") {
        await this.selectProvider();
      }
    }
  },
  methods: {
    async getOrgId() {
      // Check for an edge case where there is an org but no buyer/provider
      if (this.organizationId) {
        return this.organizationId;
      } else {
        const newOrganization = await this.$api.Organization.create();
        return newOrganization.id;
      }
    },
    async selectBuyer() {
      this.loading = true;
      try {
        const organizationId = await this.getOrgId();
        await this.$api.Organization.createBuyer(organizationId);
        // Note: this emit event needs to happen before refreshToken, or else
        // this event might not reach the parent
        this.$emit("select", "buyer");
        await this.refreshToken();
      } finally {
        this.loading = false;
      }
    },
    async selectProvider() {
      this.loading = true;
      try {
        const organizationId = await this.getOrgId();
        await this.$api.Organization.createProvider(organizationId);
        // Note: this emit event needs to happen before refreshToken, or else
        // this event might not reach the parent
        this.$emit("select", "provider");
        await this.refreshToken();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
