import { computed, ref } from "vue";
import { createEventHook } from "@vueuse/core";

import { useAuth } from "./useAuth";
import { useTokenRefresher } from "./useTokenRefresher";
import { debug, warn } from "./logging";

const MAX_REFRESH_FAILURES = 5;
const FAILED_REFRESH_DELAY = 10;
const MIN_EXPIRATION = 100;
const DEFAULT_REFRESH_DELAY = 30;
const MIN_REFRESH_PERCENT = 30;
const MAX_REFRESH_PERCENT = 90;

/**
 * Options to configure the token refresher
 */
type PeriodicTokenRefreshConfig = {
  /**
   * Default amount of time in seconds to delay the next token refresh when the backoff is below
   * the minimum threshold
   */
  defaultRefreshDelaySeconds?: number;
  /**
   * Amount of time in seconds to delay the next token refresh when the last attempt failed
   */
  failedRefreshDelaySeconds?: number;
  /**
   * Maximum number of failures that are acceptable before stopping the periodic refresh loop
   */
  maxFailures?: number;
};

/**
 * Helper function to add a random, bounded "jitter" amount for creating a non-uniform backoff
 * interval
 *
 * @param minVal - Minimum possible value
 * @param maxVal - Maximum possible value
 * @returns The random, bounded "jitter" amount
 */
function jitter(minVal: number, maxVal: number) {
  const delta = maxVal - minVal;
  return Math.random() * delta + minVal;
}

/**
 * Composable for managing the token refresh loop
 *
 * @param config - Optional control parameters to configure the periodic refresh loop
 */
export function usePeriodicTokenRefresh(config?: PeriodicTokenRefreshConfig) {
  const effectiveConfig = {
    defaultRefreshDelaySeconds:
      config?.defaultRefreshDelaySeconds ?? DEFAULT_REFRESH_DELAY,
    failedRefreshDelaySeconds:
      config?.failedRefreshDelaySeconds ?? FAILED_REFRESH_DELAY,
    maxFailures: config?.maxFailures ?? MAX_REFRESH_FAILURES,
  };

  const { refreshToken } = useTokenRefresher();
  const { jwt } = useAuth();

  const failureCount = ref(0);
  const lastTimeoutId = ref<number | null>(null);
  const periodRefreshStoppedHook = createEventHook<void>();
  const backoffInterval = computed(() => {
    //
    // Return the amount of times (in seconds) to wait until the next token refresh
    // is due. We add a large random jitter to make concurrently accessing the app
    // in multiple tabs feasible.
    //
    let expiration: number;
    let t_min, t_max: number;
    let percent: number;
    let result: number;

    const now = Date.now() / 1000; // Convert to seconds.
    if (jwt.value) {
      expiration = jwt.value.exp!;
    } else {
      debug("No access token. Using default backoff interval.");
      expiration = now + MIN_EXPIRATION;
    }

    const delta_t = expiration - now;
    if (delta_t <= MIN_EXPIRATION) {
      result = effectiveConfig.defaultRefreshDelaySeconds + jitter(-3, 3);
    } else {
      // We refresh at a random time between 30% and 90% of TTL.
      // Since our minimum for this logic to kick in is 100s, this
      // means we refresh at least 10s before the access token expires,
      // which should be good enough to complete the round trip.
      percent = delta_t / 100.0; // coerce into float.
      t_min = percent * MIN_REFRESH_PERCENT;
      t_max = percent * MAX_REFRESH_PERCENT;
      result = jitter(t_min, t_max);
    }
    return result;
  });

  /**
   * Schedules a new refresh call in the future after a minimum amount of delay time
   *
   * @param minWaitSeconds - Minimum amount of time in seconds to delay the next periodic call
   */
  function scheduleSync(minWaitSeconds: number) {
    if (lastTimeoutId.value) {
      window.clearTimeout(lastTimeoutId.value);
    }
    lastTimeoutId.value = window.setTimeout(
      () => periodicTokenRefresh(),
      minWaitSeconds * 1000
    );
  }

  /**
   * Internal function that attempts to refresh the access token and schedules a future refresh
   * based on backoff logic
   *
   * @internal
   */
  async function periodicTokenRefresh() {
    let interval;

    const didRefresh = await refreshToken();
    if (!didRefresh) {
      // Token refresh might have failed. If we haven't exhausted maxFailures,
      // then try again in a few seconds.
      // If the error is transient, we should be ok. In case of a
      // permanent failure, the access token will eventually time out
      // and the user will land on the login page.
      failureCount.value += 1;
      if (failureCount.value > effectiveConfig.maxFailures) {
        periodRefreshStoppedHook.trigger();
        return;
      }
      interval = effectiveConfig.failedRefreshDelaySeconds + jitter(-3, 3);
      warn("Token refresh failed.");
    } else {
      // Reset failure count, if successful refresh occurs
      failureCount.value = 0;
      interval = backoffInterval.value;
    }
    debug("Next token refresh in " + interval + " seconds.");
    scheduleSync(interval);
  }

  /**
   * Starts a periodic loop to refresh the access token; the first refresh is executed on call
   * unless skipInitialRefresh is set to true
   *
   * @param skipInitialRefresh - Skips the initial refresh
   */
  async function startPeriodicRefresh(skipInitialRefresh?: boolean) {
    // Clear any pre-existing timer loops
    if (lastTimeoutId.value) {
      window.clearTimeout(lastTimeoutId.value);
    }

    let succeeded;
    if (!skipInitialRefresh) {
      succeeded = await refreshToken();
    } else {
      succeeded = true;
    }
    if (succeeded) {
      scheduleSync(backoffInterval.value);
    }
    return succeeded;
  }

  return {
    startPeriodicRefresh,
    /**
     * Event hook that gets called if the periodic refresh loop stops
     */
    onPeriodicRefreshStopped: periodRefreshStoppedHook.on,
  };
}
