<template>
  <div class="tw-flex tw-flex-row tw-p-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: "t-card-title",
};
</script>
