import * as Sentry from "@sentry/vue";

interface ExtraTags {
  [key: string]: any;
}

interface User {
  accountId: string | null;
  groups: string[];
  userType: string | null;
}

/**
 * Updates the current Sentry scope to include user context
 */
export function updateSentryContext(ctx: User & ExtraTags) {
  const { accountId, groups, userType, ...extraTags } = ctx;
  if (accountId) {
    Sentry.setUser({
      id: accountId || undefined,
      groups,
      user_type: userType,
    });
  } else {
    Sentry.setUser(null);
  }
  Sentry.setTag("user_type", userType);
  Sentry.setTags(extraTags);
}

/**
 * Installs Sentry along with default uncaught exception handling
 */
export function useSentry(options: any) {
  Sentry.init({
    ...options,
    integrations: [new Sentry.Replay()],
  });
}
