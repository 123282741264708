import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const globalClient = axios.create();

export function getClient(): AxiosInstance {
  return globalClient;
}

export function setClientOptions(opts: AxiosRequestConfig) {
  for (const [k, v] of Object.entries(opts)) {
    // @ts-expect-error Pretty complicated types here, any ideas?
    globalClient.defaults[k] = v;
  }
}

// Function to recursively convert null to undefined
// I think we should actually do this for ALL network calls
export function convertNullsToUndefined(obj: any): any {
  if (obj === null) return undefined;

  if (typeof obj === "object") {
    for (const key in obj) {
      obj[key] = convertNullsToUndefined(obj[key]);
    }
  }

  return obj;
}
