import { ref } from "vue";
import { createGlobalState, useDebounceFn } from "@vueuse/core";

import { AccountClient } from "./API";

const DEBOUNCE_INTERVAL_MS = 500;

// Taken from https://github.com/StationA/stationa-api/blob/main/api/types/account.py#L11-L27
export type AccountSummary = {
  account_id: string;
  first_name: string;
  last_name: string;
  email: string;
  trial_expiration?: string;
  product: "basic" | "pro" | "enterprise";
  is_active: boolean;
  is_beta: boolean;
  groups: string[];
};

export const useAccountSummary = createGlobalState(() => {
  // Shared reactive refs
  const summary = ref<AccountSummary | null>(null);
  const loading = ref(false);
  const error = ref<any | null>(null);

  const client = new AccountClient();

  // The function that actually does the account summary loading
  function doLoad() {
    loading.value = true;
    error.value = null;
    client
      .getSummary()
      .then((s) => (summary.value = s as AccountSummary))
      .catch((e) => (error.value = e))
      .finally(() => (loading.value = false));
  }

  // Triggers initial load, assuming we're outside of the debounce interval
  const debouncedLoad = useDebounceFn(doLoad, DEBOUNCE_INTERVAL_MS);

  /**
   * Reloads the account summary, optionally forcing a reload via `forceReload`
   */
  async function reload(forceReload?: boolean) {
    if (forceReload) {
      doLoad();
    } else {
      await debouncedLoad();
    }
  }

  return {
    summary,
    loading,
    error,
    reload,
  };
});
