<script setup lang="ts">
import { computed } from "vue";

// NOTE: We're currently stuck using the "raw" import modifier in combination with v-html to embed
// the raw SVG content into a <div>. Ideally, we'd be able to use the builtin SVG loader from Vite,
// but due to some incompatibilities in our version of Vue and the unplugin-svg-vue-component
// 3rd-party implementation, this is the best we can do for the time being
import StaIcon from "@/assets/logo.svg?raw";
import StaText from "@/assets/logo-text.svg?raw";

const props = withDefaults(
  defineProps<{
    // Use this prop to include the "Station A" text part of the logo; defaults to false
    full?: boolean;
    size: number | string;
  }>(),
  {
    size: 24,
  }
);

const sizePx = computed(() => `${props.size}px`);
</script>

<template>
  <div class="sta-logo">
    <div class="sta-icon" v-html="StaIcon" />
    <div class="sta-text" v-html="StaText" v-if="full" />
  </div>
</template>

<style scoped lang="scss">
.sta-logo {
  display: inline-flex;
  align-items: center;

  .sta-icon {
    flex: 0 0 v-bind(sizePx);
    :deep(svg) {
      display: block;
      fill: currentColor;
      height: v-bind(sizePx);
      width: v-bind(sizePx);
    }
  }

  .sta-text {
    flex: 1 1 100%;
    margin-left: 16px;
    :deep(svg) {
      display: block;
      fill: currentColor;
      height: 12px;
      width: auto;
    }
  }
}
</style>
