import {
  AccountClient,
  LinkClient,
  OrganizationClient,
  RFIsClient,
} from "../lib/API";

import { PortfoliosClient } from "../lib/PortfolioAPI";

export default {
  install(Vue) {
    Vue.prototype.$api = {
      Account: new AccountClient(),
      Link: new LinkClient(),
      Organization: new OrganizationClient(),
      Portfolios: new PortfoliosClient(),
      RFIs: new RFIsClient(),
    };
  },
};
