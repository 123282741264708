import { Section } from "@/lib/OrgTypes";

export default [
  {
    key: "about_us",
    label: "About us",
    icon: "star",
    fields: [
      {
        key: "common_org_name",
        question: "What is the trade, or common name, of your organization?",
        type: "textfield",
        label: "Organization name",
        display: [],
      },
      {
        key: "legal_org_name",
        question:
          "What is your organization's full legal name used for contracting purposes?",
        type: "textfield",
        label: "Legal organization name",
        display: [],
      },
      {
        key: "year_established",
        question: "What year was your company established?",
        type: "textfield",
        label: "Year established",
        mask: "####",
        display: [],
      },
      {
        key: "website_url",
        question:
          "Please provide your company’s website URL. Paste the URL directly from your browser. Test that your URL works in your Provider Summary Card above.",
        type: "textfield",
        subtype: "url",
        label: "Website URL",
        display: [],
      },
      {
        key: "logo_divider",
        type: "divider",
        display: ["block", "read_only_hidden"],
      },
      {
        key: "logo",
        question: "Please upload your company’s logo.",
        type: "image",
        label: "Logo",
        display: ["block", "read_only_hidden"],
      },
      {
        key: "locations_divider",
        type: "divider",
        display: ["block"],
      },
      {
        key: "headquarters",
        question: "Where is your company headquartered?",
        placeholder: "eg. San Francisco, CA",
        type: "textfield",
        label: "Headquarters",
        display: [],
      },
      {
        key: "regional_offices",
        question: "If you have regional offices, please list up to 5.",
        type: "textarea",
        label: "Regional offices",
        display: [],
      },
      {
        key: "states_served",
        question: "In which US states and territories do you operate?",
        type: "list",
        display: ["show_value"],
        label: "US geographies served",
        options: [
          { value: "AL", label: "Alabama" },
          { value: "AK", label: "Alaska" },
          { value: "AZ", label: "Arizona" },
          { value: "AR", label: "Arkansas" },
          { value: "CA", label: "California" },
          { value: "CO", label: "Colorado" },
          { value: "CT", label: "Connecticut" },
          { value: "DE", label: "Delaware" },
          { value: "FL", label: "Florida" },
          { value: "GA", label: "Georgia" },
          { value: "HI", label: "Hawaii" },
          { value: "ID", label: "Idaho" },
          { value: "IL", label: "Illinois" },
          { value: "IN", label: "Indiana" },
          { value: "IA", label: "Iowa" },
          { value: "KS", label: "Kansas" },
          { value: "KY", label: "Kentucky" },
          { value: "LA", label: "Louisiana" },
          { value: "ME", label: "Maine" },
          { value: "MD", label: "Maryland" },
          { value: "MA", label: "Massachusetts" },
          { value: "MI", label: "Michigan" },
          { value: "MN", label: "Minnesota" },
          { value: "MS", label: "Mississippi" },
          { value: "MO", label: "Missouri" },
          { value: "MT", label: "Montana" },
          { value: "NE", label: "Nebraska" },
          { value: "NV", label: "Nevada" },
          { value: "NH", label: "New Hampshire" },
          { value: "NJ", label: "New Jersey" },
          { value: "NM", label: "New Mexico" },
          { value: "NY", label: "New York" },
          { value: "NC", label: "North Carolina" },
          { value: "ND", label: "North Dakota" },
          { value: "OH", label: "Ohio" },
          { value: "OK", label: "Oklahoma" },
          { value: "OR", label: "Oregon" },
          { value: "PA", label: "Pennsylvania" },
          { value: "RI", label: "Rhode Island" },
          { value: "SC", label: "South Carolina" },
          { value: "SD", label: "South Dakota" },
          { value: "TN", label: "Tennessee" },
          { value: "TX", label: "Texas" },
          { value: "UT", label: "Utah" },
          { value: "VT", label: "Vermont" },
          { value: "VA", label: "Virginia" },
          { value: "WA", label: "Washington" },
          { value: "WV", label: "West Virginia" },
          { value: "WI", label: "Wisconsin" },
          { value: "WY", label: "Wyoming" },
          { value: "AS", label: "American Samoa" },
          { value: "GU", label: "Guam" },
          { value: "MP", label: "Northern Mariana Islands" },
          { value: "PR", label: "Puerto Rico" },
          { value: "VI", label: "U.S. Virgin Islands" },
        ],
      },
      {
        key: "non_us_geographies_served",
        question:
          "If you operate outside the US, please list these other geographies.",
        type: "textarea",
        label: "Non US geographies served",
        display: [],
      },
      {
        key: "differentiation_divider",
        type: "divider",
        display: ["block"],
      },
      {
        key: "differentiation",
        question:
          "What is your company’s competitive advantage and differentiation relative to your competitors and peers?",
        type: "textarea",
        display: ["block"],
        label: "Differentiation",
      },
      {
        key: "has_financial_backing",
        question: "Is your company financially backed by another company?",
        label: "Financial backing",
        type: "binary",
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
        display: [],
      },
      {
        key: "financial_backing_description",
        question: "Please describe your financial backing.",
        label: "Financial backing description",
        type: "textarea",
        display: ["block", "indent"],
        prerequisite: "has_financial_backing",
      },
    ],
  },
  ///////////////////////////////////////////////////////////
  // Our experience
  {
    key: "experience",
    label: "Our experience",
    icon: "solar_power",
    fields: [
      {
        key: "industries_served",
        question:
          "Please select all industries in which you have performed clean energy projects in the last three years.",
        type: "list",
        label: "Industries served",
        display: ["list"],
        options: [
          { value: "retail", label: "Retail" },
          {
            value: "office_buildings_and_campuses",
            label: "Office buildings and campuses",
          },
          { value: "multi_family", label: "Multi-family housing" },
          {
            value: "schools_and_universities",
            label: "Schools and universities",
          },
          { value: "government", label: "Government" },
          {
            value: "warehouses_and_distribution_centers",
            label: "Warehouses and distribution centers",
          },
        ],
      },
      {
        key: "project_types_served",
        question: "What types of projects do you typically work on?",
        type: "list",
        label: "Project types",
        display: ["list"],
        options: [
          { value: "commercial", label: "Commercial" },
          { value: "residential", label: "Residential" },
          { value: "utility_scale", label: "Utility-scale" },
          { value: "other", label: "Other" },
        ],
      },
      {
        key: "project_divider",
        type: "divider",
        display: ["block"],
      },
      {
        key: "project_count",
        question: "How many projects did you complete in the last three years?",
        type: "numeric",
        unit: "projects",
        label: "Projects completed / 3 years",
        tooltip: "Project count completed over the last 3 years",
        display: [],
        constraints: {
          is_integer: true,
          minimum: 0,
          maximum: 10000,
        },
      },
      {
        key: "project_mw",
        question:
          "How much completed project volume did you deploy in the last 3 years (in MW DC)?",
        type: "numeric",
        unit: "MW DC",
        label: "Project volume completed / 3 years",
        tooltip: "Project MW completed over the last 3 years",
        display: ["block"],
        constraints: {
          minimum: 0,
          maximum: 100000,
        },
      },
      {
        key: "project_mw_in_house",
        question:
          "Of that project volume listed above, how many MW were installed by your company as the EPC?",
        type: "numeric",
        unit: "MW DC",
        short_label: "in house",
        label: "Project MW completed in-house",
        display: ["indent"],
        constraints: {
          minimum: 0,
          maximum: 100000,
        },
      },
      {
        key: "project_mw_other_epc",
        question:
          "Of that project volume listed above, how many MW were installed by another EPC?",
        type: "numeric",
        unit: "MW DC",
        short_label: "3rd party",
        label: "Project MW completed by 3rd party",
        display: ["indent"],
        constraints: {
          minimum: 0,
          maximum: 100000,
        },
      },
      {
        key: "change_orders_divider",
        type: "divider",
        display: ["block"],
      },
      {
        key: "change_orders",
        question:
          "How many change orders have you issued over your last 20 projects?",
        type: "numeric",
        unit: "change orders",
        label: "Change orders / 20 projects",
        tooltip: "Change orders issued over the last 20 projects",
        display: ["block"],
        constraints: {
          is_integer: true,
          minimum: 0,
          maximum: 1000,
        },
      },
      {
        key: "change_orders_buyer_requested",
        question:
          "Of those change orders listed above, how many were at the request of the buyer?",
        type: "numeric",
        unit: "change orders",
        short_label: "requested by buyer",
        label: "Change orders requested by buyer",
        display: ["indent"],
        constraints: {
          is_integer: true,
          minimum: 0,
          maximum: 1000,
        },
      },
      {
        key: "change_orders_not_buyer_requested",
        question:
          "Of those change orders listed above, how many were not at the request of the buyer?",
        type: "numeric",
        unit: "change orders",
        short_label: "not requested by buyer",
        label: "Change orders not requested by buyer",
        display: ["indent"],
        constraints: {
          is_integer: true,
          minimum: 0,
          maximum: 1000,
        },
      },
      {
        key: "emr_divider",
        type: "divider",
        display: ["block"],
      },
      {
        key: "emr",
        question: "What is your Experience Modification Rating (EMR)?",
        type: "numeric",
        label: "EMR",
        tooltip: "Experience Modification Rating (EMR)",
        display: ["block"],
        constraints: {
          minimum: -1,
          maximum: 2,
        },
      },
      {
        key: "references_divider",
        type: "divider",
        display: ["block"],
      },
      {
        key: "provider_references",
        question:
          "Please list up to 3 relevant references you want to highlight. Please include the relevant project name, size, installation type, and any installation partners involved.",
        type: "textarea",
        display: ["block"],
        label: "References",
      },
    ],
  },
  ////////////////////////////////////////////////////////////
  // What we offer
  {
    key: "products_services",
    label: "What we offer",
    icon: "shopping_cart",
    fields: [
      {
        key: "products",
        question:
          "What clean energy products do you offer? (choose all that apply)",
        type: "list",
        label: "Products offered",
        display: ["list"],
        options: [
          {
            value: "solar_carport",
            label: "Carport solar",
          },
          {
            value: "solar_rooftop",
            label: "Rooftop solar",
          },
          {
            value: "solar_groundmount",
            label: "Ground-mounted solar",
          },
          {
            value: "solar_floating",
            label: "Floating solar",
          },
          {
            value: "microgrids",
            label: "Microgrids",
          },
          {
            value: "battery",
            label: "Battery",
          },
          {
            value: "energy_efficiency",
            label: "Energy efficiency",
          },
          {
            value: "ev_charging",
            label: "EV charging",
          },
          {
            value: "community_solar",
            label: "Community solar subscription services",
          },
          {
            value: "heat_pumps",
            label: "Heat pumps",
          },
        ],
      },
      {
        key: "services",
        question:
          "What clean energy services do you offer? (choose all that apply)",
        type: "list",
        display: ["list"],
        label: "Services offered",
        options: [
          {
            value: "feasibility",
            label: "PV feasibility assessments/site visits",
          },
          {
            value: "audits",
            label: "Energy audits",
          },
          {
            value: "financing",
            label: "Project financing",
          },
          {
            value: "development",
            label: "Project development",
          },
          {
            value: "epc",
            label: "Project construction (as an EPC)",
          },
        ],
      },
      {
        key: "proprietary_technologies",
        question:
          "Does your company offer any innovative or proprietary technologies that a buyer should know about?",
        type: "textarea",
        display: ["block"],
        label: "Proprietary technologies",
      },
      {
        key: "strategic_partners",
        question:
          "Does your company have any unique supply agreements or strategic partnerships with suppliers?",
        type: "textarea",
        display: ["block"],
        label: "Strategic partners",
      },
    ],
  },
  ///////////////////////////////////////////////////////////
  // Supporting your ESGs
  {
    key: "esg_criteria",
    label: "Our ESG approach",
    icon: "forest",
    fields: [
      {
        key: "environmental_goals_initiatives",
        question:
          "Does your company have any climate targets or environmental initiatives that you want to highlight?",
        type: "textarea",
        display: ["block"],
        label: "Climate targets and environmental initiatives",
      },
      {
        key: "social_responsibility",
        question:
          "What is your company's approach to social responsibility and community engagement?",
        type: "textarea",
        display: ["block"],
        label: "Approach to social responsibility",
      },
      {
        key: "dei",
        question:
          "Does your company have policies or facilitate programs centered around promoting diversity, equity, and inclusion?",
        type: "textarea",
        display: ["block"],
        label: "DEI programs",
      },
      {
        key: "ethical_employee_treatment",
        question:
          "What measures does your company take to ensure the ethical treatment of its employees and suppliers?",
        type: "textarea",
        display: ["block"],
        label: "Ethical employee treatment",
      },
      {
        key: "underrepresented_owner",
        question:
          "Is your company owned by an underrepresented community (e.g. women-owned, BIPOC-owned, or LGBTQ+-owned)?",
        label: "Underrepresented ownership",
        type: "binary",
        tooltip: "e.g. women-owned, BIPOC-owned, or LGBTQ+-owned",
        options: [
          {
            value: true,
            label: "Yes",
            icon: "check",
          },
          {
            value: false,
            label: "No",
            icon: "close",
          },
        ],
        display: [],
      },
    ],
  },
] as Array<Section>;
