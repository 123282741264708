import { readonly, ref, watch } from "vue";
import { useAuth } from "./useAuth";
import { TermsClient } from "./TermsAPI";
import { createSharedComposable } from "@vueuse/core";

export const useTerms = createSharedComposable(function () {
  const { isAuthed } = useAuth();
  const client = new TermsClient();

  const termsLoading = ref(false);
  const termsAreUpToDate = ref(false);

  async function checkTerms() {
    termsLoading.value = true;
    try {
      termsAreUpToDate.value = await client.termsAccepted();
    } finally {
      termsLoading.value = false;
    }
  }

  async function acceptTerms() {
    termsLoading.value = true;
    try {
      termsAreUpToDate.value = await client.acceptTerms();
    } finally {
      termsLoading.value = false;
    }
  }

  watch(
    isAuthed,
    (newIsAuthed) => {
      if (newIsAuthed) {
        checkTerms();
      }
    },
    { immediate: true }
  );

  return {
    termsAreUpToDate: readonly(termsAreUpToDate),
    termsLoading: readonly(termsLoading),
    acceptTerms,
    checkTerms,
  };
});
