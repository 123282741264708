<template>
  <div class="tw-p-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: "t-card-text",
};
</script>
