type Environment = {
  // Debug flag controls whether or not debug messages are logged to the console; this flag also
  // determines whether or not to mount the /test and /impersonate routes.
  debug: boolean;
  // Dev-mode flag controls whether or not to enable token refreshing and fake API latency.
  devMode: boolean;
  // Environment is a string variable that is primarily used to set the environment labels present
  // in logs and Sentry alerts.
  environment: "development" | "staging" | "production";
  // Version is a string variable that is primarily used to set the app's version number label
  // present in logs and Sentry alerts.
  version: string;
  // App base URL is a URL variable that contains the app's intended base URL and is only used to
  // enable 3rd party auth redirects.
  appBaseUrl: string;
  // Accounts base URL is a URL variable that contains the base URL for the accounts app.
  accountsBaseUrl: string;
  // API base URL is a URL variable that contains the base URL for the Station A API and is
  // primarily used to configure the app's core API client.
  apiBaseUrl: string;
  // Auth base URL is a URL variable that contains the base URL for the Station A API and is
  // primariyl used to configure the app's auth client; this is useful when testing auth separately
  // from other API functionality and vice versa. Defaults to the value set in apiBaseUrl.
  authBaseUrl: string;
  // Auth0 base URL is a URL variable that contains the base URL for Auth0's OAuth flow and is
  // primarily used to confifugre the app's auth redirects
  auth0BaseUrl: string;
  // Auth client ID is a string variable that contains the client ID from Auth0 that corresponds to
  // the current Auth0 tenant and is primarily used to configure logout URLs
  authClientId: string;
  // Beta API base URL is a URL variable that contains the base URL for the "beta API" and is
  // primarily used to configure the app's beta API client (currently only for accessing the content
  // search endpoints)
  betaApiBaseUrl: string;
  // Beacon app ID is a string variable that contains the app ID we are reporting to the Beacon SDK;
  // this should almost always be set to "app.stationa.com"
  beaconAppId: string;
  // Beacon API base URL is a URL variable that contains the base URL for the Beacon API and is
  // primarily used to configure the Beacon SDK
  beaconApiBaseUrl: string;
  // Sentry DSN is an optional string variable that may contain Sentry's client-side DSN token for
  // authenticating the Sentry SDK. This should _never_ contain the server-side token!
  sentryDsn?: string;
  // Fake API latency is an optional numeric variable that may contain a number (in miliseconds) of
  // artificial latency to add to all in-app API calls. This will only every work in development
  // mode.
  fakeApiLatency?: number;
  // Impersonate allows you to auto-impersonate a specific account on app load. Only used when
  // devMode is true.
  impersonate?: string;
  // Fake API error rate is an optional numeric variable that specifies a probability value [0, 1)
  // for adding random API errors to all in-app API calls. Only used when devMode is true.
  fakeApiErrorRate?: number;
};

const env: Environment = {
  debug: import.meta.env.VITE_ENV !== "production",
  devMode: import.meta.env.VITE_ENV === "development",
  environment: import.meta.env.VITE_ENV,
  version: import.meta.env.VITE_VERSION,
  appBaseUrl: import.meta.env.VITE_APP_BASE_URL,
  accountsBaseUrl: import.meta.env.VITE_ACCESS_APP_BASE_URL,
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  authBaseUrl:
    import.meta.env.VITE_AUTH_BASE_URL ?? import.meta.env.VITE_API_BASE_URL,
  auth0BaseUrl: import.meta.env.VITE_AUTH0_BASE_URL,
  authClientId: import.meta.env.VITE_AUTH_CLIENT_ID,
  betaApiBaseUrl: import.meta.env.VITE_BETA_API_BASE_URL,
  beaconAppId: import.meta.env.VITE_BEACON_APP_ID,
  beaconApiBaseUrl: import.meta.env.VITE_BEACON_API_BASE_URL,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  fakeApiLatency: import.meta.env.VITE_FAKE_API_LATENCY,
  impersonate: import.meta.env.VITE_IMPERSONATE,
  fakeApiErrorRate: import.meta.env.VITE_FAKE_API_ERROR_RATE,
};

if (env.debug) {
  console.debug("Current environment:", env);
}

export default env;
