<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{ icon }}</v-icon>
      <h2 class="headline">{{ cardTitle }}</h2>
    </v-card-title>
    <v-card-text class="subheading pa-4 d-flex justify-center">
      <v-progress-circular
        color="primary darken-2 ma-4"
        size="80"
        indeterminate
      ></v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "loading-state-card",
  props: {
    cardTitle: {
      type: String,
      default: "Loading",
    },
    icon: {
      type: String,
      default: "sync",
    },
  },
};
</script>
