import { BaseAPIClient } from "./API";

export class TermsClient extends BaseAPIClient {
  async termsAccepted() {
    const url = "/account/terms_accepted";
    const res = await this.safeRequest({ url });
    return res.data.current_tos_accepted === true;
  }
  async acceptTerms() {
    const url = "/account/terms_accepted";
    const res = await this.safeRequest({ method: "POST", url });
    return res.status === 200;
  }
}
