import { ref } from "vue";
import { createGlobalState } from "@vueuse/core";
import Cookies from "universal-cookie";

const ACCESS_TOKEN_COOKIE = "access_token";
const COOKIES = new Cookies();

/**
 * Globally-shared, reactive raw access token value
 */
const useGlobalAccessToken = createGlobalState(() =>
  ref(readAccessTokenCookie())
);

/**
 * Non-reactive, one-shot getter for current access token
 */
export function getAccessToken() {
  const accessToken = useGlobalAccessToken();
  return accessToken.value;
}

/**
 * Helper function to read raw access token from cookie
 */
export function readAccessTokenCookie() {
  return COOKIES.get<string>(ACCESS_TOKEN_COOKIE);
}

/**
 * Helper function to clear our the raw access token cookie;
 */
export function clearAccessTokenCookie() {
  COOKIES.remove(ACCESS_TOKEN_COOKIE, { path: "/" });
}

/**
 * Composable for working with the raw access token
 */
export function useAccessToken() {
  const accessToken = useGlobalAccessToken();

  function setAccessToken(newToken: string) {
    accessToken.value = newToken;
  }

  function clearAccessToken() {
    clearAccessTokenCookie();
    accessToken.value = "";
  }

  return {
    accessToken,
    setAccessToken,
    clearAccessToken,
  };
}
